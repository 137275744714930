//domain/url scripts
function getHostname() {
    return ( window && window.location ? window.location.hostname : '' ) || '';
}

function isLocalhost() {
    const hostname = getHostname();
    return [ 'localhost', 'lvh.me', '127.0.0.1', '[::1]' ].includes( hostname );
}

let environment = window.biwEnvironment || '';
function getEnvironment() {
    if ( !environment ) {
        if ( isLocalhost() ) {
            environment = 'qa';
        } else {
            const hostname = getHostname();
            environment = hostname.toLowerCase().split( '.' ).find( function ( chunk ) {
                return [ 'qa', 'pprd' ].indexOf( chunk ) >= 0;
            } );
        }
    }
    return environment;
}

let domain = window.biwDomain || '';

function getDomain() {
    if ( !domain ) {
        if ( isLocalhost() ) {
            domain = 'biw.cloud';
        } else {
            const hostname = getHostname();
            const subdomains = hostname.split( '.' );
            domain = subdomains.slice( subdomains.length - 2 ).join( '.' );
        }
    }
    return domain;
}

function getURL( url ) {
    const env = getEnvironment();
    const domain = getDomain();
    if ( domain === 'biw.cloud' ) {
        url = url.toLowerCase();
        url = url.replace( '(env)', env ? env : '' );
        url = url.replace( '(env.)', env ? `${ env }.` : '' );
        url = url.replace( '(domain)', domain );

        return url;
    } else {
        const hostname = getHostname();
        return ( `https://${ hostname }/api/v1/branding/assets` );
    }
}

function getEnvUrl( url ) {
    const env = getEnvironment();
    url = url.toLowerCase();
    url = url.replace( '(env.)', env ? `${ env }.` : '' );
    return url;
}
//CSS and Favicon script
async function fetchAndApplyCss() {
    try {
        // Fetch Favicon content from the URL
        const url = getURL( 'https://(env.)api.biw.cloud/v1/branding/assets' );

        const response = await fetch( `${ url }/favicon` );
        const faviconResponse = await response.json();

        const faviconUrl = faviconResponse.value || '';

        // Create link element for the favicon
        if ( faviconUrl ) {
            const faviconLink = document.createElement( 'link' );
            faviconLink.rel = 'icon';
            faviconLink.type = 'image/png';
            faviconLink.href = faviconUrl;

            document.head.appendChild( faviconLink );
        }

        // Create the main link element for css content
        const mainLink = document.createElement( 'link' );
        mainLink.type = 'text/css';
        mainLink.rel = 'stylesheet';
        mainLink.referrerPolicy = 'origin';
        mainLink.crossOrigin = 'anonymous';
        mainLink.href = `${ url }/css`;
        document.head.appendChild( mainLink );
    } catch ( error ) {
        console.error( 'Error fetching or applying CSS and Favicon:', error );
    }
}

//anti-clickjacking script
( function () {
    let antiClickjack = document.getElementById( 'antiClickjack' );
    if ( !antiClickjack ) {
        antiClickjack = document.createElement( 'style' );
        antiClickjack.setAttribute( 'id', 'antiClickjack' );
        antiClickjack.setAttribute( 'type', 'text/css' );
        antiClickjack.appendChild( document.createTextNode( 'body { display: none !important; }' ) );
        document.head.appendChild( antiClickjack );
    }

    if (
        self === top ||
        ( location.origin === top.location.origin && location.pathname === '/oidc_renew' )
    ) {
        antiClickjack.parentNode.removeChild( antiClickjack );
    } else {
        top.location = self.location;
    }
} )();

//environment script
( function () {
    function getEnvironment() {
        if ( /localhost/.test( window.location.hostname ) ) {
            return 'qa';
        }
        return window.location.hostname.toLowerCase().split( '.' ).find( function ( chunk ) {
            let env = [ 'qa', 'pprd' ].indexOf( chunk ) >= 0;
            if ( !env ) {
                env = 'prod';
            }
            return env;
        } );
    }

    window.APP_ENV = getEnvironment();
} )();

//PWA/Manifest script
async function fetchManifestJson() {
    try {
        // get the correct url for the manifest
        const url = getURL( 'https://(env.)api.biw.cloud/v1/branding/assets' );

        const manifestLink = document.createElement( 'link' );
        manifestLink.rel = 'manifest';
        manifestLink.href = `${ url }/manifest`;
        document.head.appendChild( manifestLink );

    } catch ( error ) {
        console.error( 'Error fetching or applying manifest:', error );
    }
}

//PWA meta tag theme color
async function fetchMetaThemeColor() {
    try {
        const url = getURL( 'https://(env.)api.biw.cloud/v1/branding/assets' );
        //get all the colors in case we want something additional in the future
        const response = await fetch( `${ url }/colors` );
        const styles = await response.json();
        //get the primary color from the response
        const primary = styles.value.filter( color => color.key === 'color-primary-none' );
        //create the meta tag
        const metaTag = document.createElement( 'meta' );
        metaTag.name = 'theme-color';
        //only append it to the head if the primary color exists (which it always should)
        if ( primary && primary.length > 0 ) {
            metaTag.content = primary[ 0 ].value;
            document.head.appendChild( metaTag );
        }
    } catch ( error ) {
        console.error( 'Error fetching or applying manifest:', error );
    }
}

function preconnect( url, isBIW ) {
    let resolvedUrl = url;
    if ( isBIW ) {
        resolvedUrl = getEnvUrl( url );
    }
    const link = document.createElement( 'link' );
    link.rel = 'preconnect';
    link.href = resolvedUrl;
    if ( !isBIW ) {
        link.crossOrigin = true;
    }
    document.head.appendChild( link );
}

if ( location.pathname !== '/oidc_renew' ) {
    preconnect( 'https://(env.)api.biw.cloud', true );
    preconnect( 'https://fonts.gstatic.com' );
    preconnect( 'https://cdnjs.cloudflare.com' );
    fetchMetaThemeColor();
    fetchAndApplyCss();
    fetchManifestJson();
}
